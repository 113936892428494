import classNames from "classnames"
import format from "date-fns/format"
import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { DockwaPlusMembershipCardSmall } from "src/main/Account/Settings/DockwaPlusMembershipCard"

import Form from "src/components/Form"
import Modal from "src/components/Modal"
import SelectPaymentMethod from "src/components/SelectPaymentMethod/SelectPaymentMethod"

import { createDockwaPlusMembership } from "src/api/Account/Settings"

import { useToast } from "src/hooks/use_toast"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const ACCEPTED_PAYMENT_METHODS = ["card"]

const DockwaPlusMembershipSignupModal = ({
  isOpen,
  setIsOpen,
  setUser,
  dockwaPlusProductInformation,
  paymentMethods,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("")
  const creditCards = paymentMethods?.filter(
    (method) => method.type === "PaymentMethod::Card"
  )
  const showToast = useToast()

  const handleClose = () => {
    setIsOpen(false)
  }

  const { mutate: createMembership, isLoading: creatingMembership } =
    useMutation((data) => createDockwaPlusMembership({ data }), {
      onSuccess: (data) => {
        showToast(
          `You’ve successfully signed up for Dockwa+! You’ll retain access to your benefits through ${data.dockwaPlusSubscription?.readableCurrentPeriodEnd}`,
          { type: "success" }
        )
        setUser(data)
        handleClose()
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    })

  const submitButtonText = () => {
    if (creatingMembership) {
      return "Purchasing"
    } else {
      return "Purchase Dockwa+"
    }
  }

  const paymentMethodZipCode = () => {
    const method = creditCards.find(
      (method) => method.stripePaymentMethodId === paymentMethod
    )

    return method?.metadata?.zip
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { zipCode: "" },
  })

  const handleCreateMembership = (data) => {
    createMembership({
      paymentMethodId: paymentMethod,
      postalCode: paymentMethodZipCode() || data.zipCode,
      dockwaPlusVersion: dockwaPlusProductInformation.dockwa_plus_version,
    })
  }

  const renderMembershipInformation = () => (
    <div className="pb-4">
      <div className="flex space-x-4">
        <DockwaPlusMembershipCardSmall />
        <div className="flex flex-col space-y-2">
          <span className="font-bold">Dockwa+ Membership</span>
          <span className="text-gray-600">
            Renews on{" "}
            {dockwaPlusProductInformation &&
              format(
                new Date(dockwaPlusProductInformation.renewal_date),
                "MMM d, yyyy"
              )}
          </span>
          <span className="text-gray-600">Discounts apply automatically</span>
        </div>
      </div>
    </div>
  )

  const renderPaymentMethodForm = () => (
    <div className="flex flex-col space-y-2">
      <div className="mt-2 flex space-x-4">
        <SelectPaymentMethod
          acceptedPaymentMethods={ACCEPTED_PAYMENT_METHODS}
          paymentMethods={creditCards}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
        {paymentMethod && !paymentMethodZipCode() && (
          <div className="flex flex-col self-end">
            <Form.Label htmlFor="zipCode">Zip Code</Form.Label>
            <Form.TextField
              id="zipCode"
              {...register("zipCode", { required: "Zip code is required" })}
              hasErrors={!!errors?.zipCode}
            />
          </div>
        )}
      </div>
      {errors.zipCode && <Form.Error>{errors.zipCode.message}</Form.Error>}
    </div>
  )

  const renderLineItems = () => (
    <div className="mb-4 grid w-1/3 grid-cols-2 gap-2">
      {dockwaPlusProductInformation?.line_items
        ?.filter((item) => item.type !== "horizontal_rule")
        ?.map((item) => {
          return (
            <Fragment key={item.type}>
              <span className="col-span-1">{item.label}:</span>
              <span
                className={classNames({
                  "text-gray-600": item.type !== "total",
                  "font-semibold": item.type === "total",
                })}
              >
                {formattedCentsToDollars(item.amount)}
              </span>
            </Fragment>
          )
        })}
    </div>
  )

  const renderLinks = () => (
    <span>
      By completing this purchase, you agree to{" "}
      <a
        href="https://ahoy.dockwa.com/about-us/terms-of-service"
        className="font-semibold text-blue-600"
        rel="noreferrer noopener"
        target="_blank"
      >
        Dockwa Terms and Conditions
      </a>{" "}
      and{" "}
      <a
        href="https://ahoy.dockwa.com/privacy"
        className="font-semibold text-blue-600"
        rel="noreferrer noopener"
        target="_blank"
      >
        Privacy Policy
      </a>
    </span>
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header title="Sign up for Dockwa+" />
      <Form onSubmit={handleSubmit(handleCreateMembership)}>
        <Modal.Body>
          <div className="pb-4">
            {renderMembershipInformation()}
            {renderPaymentMethodForm()}
            <div className="my-4 w-full border-b" />
            {renderLineItems()}
            {renderLinks()}
          </div>
        </Modal.Body>
        <Modal.Footer
          onClose={handleClose}
          confirmBtnText={submitButtonText()}
          confirmBtnVariant="primary"
          confirmBtnLoading={creatingMembership}
          confirmBtnType="submit"
          disabled={!paymentMethod}
          onSubmit={() => {}}
          cancelBtnText="Cancel"
          disableCancelBtn={creatingMembership}
        />
      </Form>
    </Modal>
  )
}

DockwaPlusMembershipSignupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  dockwaPlusProductInformation: PropTypes.shape({
    dockwa_plus_external_id: PropTypes.string.isRequired,
    dockwa_plus_version: PropTypes.number.isRequired,
    line_items: PropTypes.array.isRequired,
    renewal_date: PropTypes.string.isRequired,
  }),
  paymentMethods: PropTypes.array,
}

export default DockwaPlusMembershipSignupModal
