import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { format } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import { titlecase } from "src/utils/string_helpers"

import { dateToLoad } from "./DateTimeInput"

const CruiseStopItem = ({ stop, cruisePlanId }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: stop.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  const renderDates = () => {
    return (
      <div className="flex items-center gap-2 font-semibold text-gray-900 max-lg:hidden">
        {stop.startDate && (
          <span className="flex flex-col gap-0.5">
            {format(new Date(stop.startDate), "MMM dd, yyyy")}
            {format(dateToLoad(stop.startDate), "HH:mm") !== "00:00" && (
              <span className="font-normal text-gray-600">
                {format(dateToLoad(stop.startDate), "hh:mm a")}
              </span>
            )}
          </span>
        )}
        {stop.startDate && stop.endDate && <span>-</span>}
        {stop.endDate && (
          <span className="flex flex-col gap-0.5">
            {format(new Date(stop.endDate), "MMM dd, yyyy")}
            {format(dateToLoad(stop.endDate), "HH:mm") !== "00:00" && (
              <span className="font-normal text-gray-600">
                {format(dateToLoad(stop.endDate), "hh:mm a")}
              </span>
            )}
          </span>
        )}
      </div>
    )
  }

  const actions = (type, stop) => {
    return (
      <div className="flex w-full justify-end">
        <a
          href={`/account/cruise_plans/${cruisePlanId}/cruise_stops/${type}_stops/${stop.id}/edit`}
          className="block p-2 text-sm text-gray-700"
          data-confirm="Are you sure you want to delete this stop?"
        >
          Edit
        </a>
      </div>
    )
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="grid cursor-move grid-cols-[20px_repeat(2,_minmax(0,_1fr))] items-center gap-4 overflow-hidden rounded-lg border border-gray-200 bg-white p-6 shadow lg:grid-cols-[20px_repeat(2,_minmax(0,_1fr))_minmax(0,_2fr)_100px]"
    >
      {stop.type === "CruiseStop::LocationStop" && (
        <>
          <i className="icon icon-md-location-on text-lg text-gray-600" />
          <div className="text-lg font-bold">{stop.name}</div>
          {renderDates()}
          <div className="text-gray-900 max-lg:hidden">{stop.note}</div>
          {actions("location", stop)}
        </>
      )}
      {stop.type === "CruiseStop::MarinaStop" && (
        <>
          <i className="icon icon-ship-store text-lg text-gray-600" />
          <div className="text-lg font-bold">{stop.marina.name}</div>
          {renderDates()}
          <div className="text-gray-900 max-lg:hidden">{stop.note}</div>
          {actions("marina", stop)}
        </>
      )}
      {stop.type === "CruiseStop::NoteStop" && (
        <>
          <i className="icon icon-document text-lg text-gray-600" />
          <div className="text-lg font-bold">{stop.title}</div>
          {renderDates()}
          <div className="text-gray-900 max-lg:hidden">{stop.note}</div>
          {actions("note", stop)}
        </>
      )}
      {stop.type === "CruiseStop::ReservationStop" && (
        <>
          <i className="icon icon-calendar-check-o text-lg text-blue-500" />
          <div className="flex flex-col text-lg font-bold">
            <span className="text-sm font-semibold text-blue-500">
              Dockwa Reservation
            </span>
            <span>{stop.marina.name}</span>
          </div>
          {renderDates()}
          <div className="text-gray-900 max-lg:hidden">
            <span className="mr-4 rounded bg-blue-100 px-2 py-0.5 font-bold text-blue-900">
              {titlecase(stop.reservation.status)}
            </span>
            {stop.note}
          </div>
          {actions("reservation", stop)}
        </>
      )}
    </div>
  )
}

CruiseStopItem.propTypes = {
  stop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    note: PropTypes.string,
    marina: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    reservation: PropTypes.shape({
      status: PropTypes.string,
    }),
  }).isRequired,
  cruisePlanId: PropTypes.string.isRequired,
}

export default CruiseStopItem
