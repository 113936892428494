import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { createStop, updateStop } from "src/api/CruisePlan/mutateStop"

import DateTimeInput, { dateToLoad, dateToSave } from "./DateTimeInput"

const NoteStopForm = ({ cruiseStop }) => {
  const cruisePlan = cruiseStop.cruisePlan

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: cruiseStop.title,
      note: cruiseStop.note,
      startDate: cruiseStop.startDate ? dateToLoad(cruiseStop.startDate) : null,
      endDate: cruiseStop.endDate ? dateToLoad(cruiseStop.endDate) : null,
    },
  })

  const { mutate } = useMutation({
    mutationFn: (data) => {
      data.start_date = dateToSave(data.startDate)
      data.end_date = dateToSave(data.endDate)
      if (cruiseStop.id) {
        return updateStop({
          type: cruiseStop.type,
          cruisePlanId: cruisePlan.id,
          id: cruiseStop.id,
          data: data,
        })
      } else {
        return createStop({
          type: cruiseStop.type,
          cruisePlanId: cruisePlan.id,
          data: data,
        })
      }
    },
    onSuccess: (reponse) => {
      window.location.href = reponse.redirect_url
    },
    onError: (reponse) => {
      alert(reponse.errors)
    },
  })

  const onSubmit = (data) => {
    mutate(data)
  }

  return (
    <div className="container max-w-5xl">
      <a href={`/account/cruise_plans/${cruisePlan.id}`} className="text-link">
        ← Back to cruise plan
      </a>
      <div className="card mt-4">
        <h1 className="my-0 text-xl font-bold">Add a Note</h1>
        <p className="mb-4">
          Create a note to jot down reminders, navigation aides, or places you
          want to visit.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <Form.Label htmlFor="title">Title</Form.Label>
            <Form.TextField
              id="title"
              {...register(`title`, { required: "Title is required" })}
              hasErrors={!!errors?.title}
            />
            {errors?.title && <Form.Error>{errors?.title?.message}</Form.Error>}
          </div>

          <DateTimeInput
            name="startDate"
            control={control}
            watch={watch}
            errors={errors}
          />

          <DateTimeInput
            name="endDate"
            control={control}
            watch={watch}
            errors={errors}
          />

          <div>
            <Form.Label htmlFor="note">Note</Form.Label>
            <Form.Textarea
              id="note"
              {...register(`note`)}
              hasErrors={!!errors?.note}
            />
            {errors?.note && <Form.Error>{errors?.note?.message}</Form.Error>}
          </div>

          <Button variant="primary" type="submit">
            {cruiseStop.id ? "Update Note" : "Add Note"}
          </Button>
        </form>
      </div>
    </div>
  )
}

NoteStopForm.propTypes = {
  cruiseStop: PropTypes.shape({
    id: PropTypes.string,
    cruisePlan: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
    note: PropTypes.string,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    type: PropTypes.string,
  }).isRequired,
}

export default NoteStopForm
