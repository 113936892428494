import * as yup from "yup"

const itemSchema = yup
  .object()
  .shape(
    {
      name: yup.string(),
      amount: yup.number(),
      productId: yup.string(),
      taxRate: yup.number(),
      quantity: yup.number(),
      pricePerUnit: yup.number().min(0, ""),
      pricePrecision: yup.string(),
      startDate: yup.string(),
      externalSale: yup.boolean(),
      requestExternalSettlement: yup.boolean(),
      endDate: yup
        .string()
        .nullable()
        .when("dueDay", {
          is: (dueDay) => !!dueDay,
          then: () =>
            yup
              .string()
              .nullable()
              .test(
                "must not be null",
                "Recurring item must have an end date in a fixed period contract.",
                (endDate, context) => {
                  const [parent] = context.from.slice(-1)
                  if (parent.value.billingCycle === "installments") {
                    return !!endDate
                  }
                  return true
                }
              ),
        }),
      originalAmount: yup.number().when("dueDay", {
        is: (dueDay) => !!dueDay,
        then: () => yup.number().required("Amount is required for this item."),
      }),
      dueDate: yup
        .string()
        .nullable()
        .when(["dueDay", "dueOnSignature", "addToInstallments"], {
          is: (dueDay, dueOnSignature, addToInstallments) => {
            return !!dueDay || !!dueOnSignature || !!addToInstallments
          },
          then: () =>
            yup
              .string()
              .nullable(true)
              .test(
                "must be null",
                "Due date must be null for recurring items",
                (dueDate) => !dueDate
              ),
          otherwise: () => yup.string().required("Due date is required."),
        }),
      dueDay: yup
        .number()
        .nullable()
        .when(["dueDate", "dueOnSignature", "addToInstallments"], {
          is: (dueDate, dueOnSignature, addToInstallments) => {
            return !!dueDate || !!dueOnSignature || !!addToInstallments
          },
          then: () =>
            yup
              .string()
              .nullable(true)
              .test(
                "must be null",
                "Monthly due day must be null for non-recurring items",
                (dueDay) => !dueDay
              ),
          otherwise: () => yup.string().required("Due day is required."),
        }),
      dueOnSignature: yup.boolean().nullable(),
    },
    ["dueDate", "dueDay", "dueOnSignature", "addToInstallments"]
  )
  .required()

export const posItemsDataSchema = yup.array().of(itemSchema)
