import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import OverflowMenu from "src/components/OverflowMenu"

import updateCruiseStopOrder from "src/api/CruisePlan/updateCruiseStopOrder"

import { useToast } from "src/hooks/use_toast"

import CruisePlanEmptyState from "./CruisePlanEmptyState"
import CruiseStopItem from "./CruiseStopItem"

const CruisePlan = ({ cruisePlan }) => {
  const [stops, setStops] = useState(cruisePlan.cruiseStops)
  const { showToast } = useToast()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const { mutate } = useMutation({
    mutationFn: (data) => updateCruiseStopOrder(cruisePlan.id, data),
    onError: () => {
      showToast("Error updating sort order", { type: "error" })
    },
  })

  const handleDragEnd = (event) => {
    const { active, over } = event
    if (active.id !== over.id) {
      setStops((stops) => {
        const oldIndex = stops.findIndex((stop) => stop.id === active.id)
        const newIndex = stops.findIndex((stop) => stop.id === over.id)
        const newOrder = arrayMove(stops, oldIndex, newIndex)
        const stopOrder = newOrder.map((stop, index) => ({
          id: stop.id,
          display_order: index,
        }))
        mutate(stopOrder)
        return newOrder
      })
    }
  }

  const renderStopsDropdown = () => {
    return (
      <div className="relative mb-4 inline-block text-left">
        <OverflowMenu menuButtonLabel="Add to Plan">
          <div className="py-1">
            <a
              href={`/account/cruise_plans/${cruisePlan.id}/cruise_stops/reservation_stops/new`}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-black hover:no-underline"
            >
              Add Reservation
            </a>
            <a
              href={`/account/cruise_plans/${cruisePlan.id}/cruise_stops/marina_stops/new`}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-black hover:no-underline"
            >
              Add Marina
            </a>
            <a
              href={`/account/cruise_plans/${cruisePlan.id}/cruise_stops/location_stops/new`}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-black hover:no-underline"
            >
              Add Location
            </a>
            <a
              href={`/account/cruise_plans/${cruisePlan.id}/cruise_stops/note_stops/new`}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-black hover:no-underline"
            >
              Add Note
            </a>
          </div>
        </OverflowMenu>
      </div>
    )
  }

  const renderStops = () => {
    return (
      <>
        {renderStopsDropdown()}
        <div className="flex flex-col gap-6">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={stops}
              strategy={verticalListSortingStrategy}
            >
              {stops.map((stop) => (
                <CruiseStopItem
                  key={stop.id}
                  stop={stop}
                  cruisePlanId={cruisePlan.id}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </>
    )
  }

  return (
    <div className="container mb-8">
      <div className="flex items-center justify-between">
        <a href="/account/cruise_plans" className="text-link">
          ← Cruise plans
        </a>
        <a
          href={`/account/cruise_plans/${cruisePlan.id}/edit`}
          className="btn btn-secondary"
        >
          Edit plan details
        </a>
      </div>

      <div className="flex gap-4">
        <img
          className="cover size-24 rounded object-cover"
          src={cruisePlan.coverImage}
        />
        <div className="flex flex-col">
          <div className="text-2xl font-bold">{cruisePlan.name}</div>
          <div className="text-gray-500">{cruisePlan.description}</div>
        </div>
      </div>

      <hr className="my-4" />
      {stops.length > 0 ? (
        renderStops()
      ) : (
        <CruisePlanEmptyState
          title="No stops yet"
          description="Add your first stop."
          button={renderStopsDropdown()}
        />
      )}
    </div>
  )
}

CruisePlan.propTypes = {
  cruisePlan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    cruiseStops: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    coverImage: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default CruisePlan
