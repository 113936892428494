import PropTypes from "prop-types"
import React, { useState } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import LoginAndSecurity from "src/main/Account/Settings/LoginAndSecurity"
import Marinas from "src/main/Account/Settings/Marinas"
import Memberships from "src/main/Account/Settings/Memberships"
import PaymentMethods from "src/main/Account/Settings/PaymentMethods"
import PersonalInfo from "src/main/Account/Settings/PersonalInfo"
import Settings from "src/main/Account/Settings/index"

const SettingsRouter = ({
  user: currentUser,
  paymentMethods,
  dockwaPlusFeatureEnabled,
}) => {
  const [user, setUser] = useState(currentUser)

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: (
          <Settings
            emailConfirmed={user.emailConfirmed}
            marinaEmployee={user.marinaEmployee}
          />
        ),
        children: [
          {
            path: "/personal_info",
            element: <PersonalInfo user={user} setUser={setUser} />,
          },
          {
            path: "/payment_methods",
            element: <PaymentMethods paymentMethods={paymentMethods} />,
          },
          {
            path: "/login_and_security",
            element: (
              <LoginAndSecurity minimumPasswordLength={user.passwordMinimum} />
            ),
          },
          {
            path: "/memberships",
            element: (
              <Memberships
                user={user}
                setUser={setUser}
                dockwaPlusFeatureEnabled={dockwaPlusFeatureEnabled}
                paymentMethods={paymentMethods}
              />
            ),
          },
          {
            path: "/marinas",
            element: <Marinas usersMarinas={user.usersMarinas} />,
          },
          {
            path: "*",
            element: <PersonalInfo user={user} setUser={setUser} />,
          },
        ],
      },
    ],
    { basename: `/account/settings` }
  )

  return <RouterProvider router={router} />
}

SettingsRouter.propTypes = {
  dockwaPlusFeatureEnabled: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isEditable: PropTypes.bool.isRequired,
      stripePaymentMethodId: PropTypes.string,
      stripeFingerprintId: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      logo: PropTypes.string,
      reservationCount: PropTypes.number.isRequired,
      status: PropTypes.oneOf([
        "initialized",
        "requires_confirmation",
        "requires_action",
        "requires_reauthorization",
        "processing",
        "succeeded",
        "failed",
        "blocked",
      ]),
    })
  ),
  user: PropTypes.shape({
    avatarBackgroundColor: PropTypes.string,
    avatarInitial: PropTypes.string,
    avatarUrl: PropTypes.string,
    boatusId: PropTypes.string,
    boatusLastName: PropTypes.string,
    boatusStatus: PropTypes.string,
    boatusZip: PropTypes.string,
    brewerFirstName: PropTypes.string,
    brewerId: PropTypes.string,
    brewerLastName: PropTypes.string,
    email: PropTypes.string,
    emailConfirmed: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    passwordMinimum: PropTypes.number.isRequired,
    phone: PropTypes.string,
    safeHarborMember: PropTypes.bool.isRequired,
    unconfirmedEmail: PropTypes.string,
    userDeleted: PropTypes.bool.isRequired,
    usersMarinas: PropTypes.arrayOf(
      PropTypes.shape({
        allowedToReceiveDockmasterNotifications: PropTypes.bool.isRequired,
        allowedToReceiveFinancialNotifications: PropTypes.bool.isRequired,
        dashboardPath: PropTypes.string.isRequired,
        dockmasterNotifications: PropTypes.bool.isRequired,
        financialNotifications: PropTypes.bool.isRequired,
        id: PropTypes.number.isRequired,
        marinaId: PropTypes.number.isRequired,
        marinaName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
      })
    ),
  }),
}

export default SettingsRouter
