import { isBefore, parseISO, startOfToday } from "date-fns"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import { DangerousDeprecatedOldReactAdapterComponent } from "src/components/__dangerous__"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import CancelContractModal from "./CancelContractModal"
import FeatureFlagContextProvider from "./FeatureFlagContext"
import Items from "./Items"
import LegacyItinerarySection from "./LegacyItinerarySection"
import LegacyNightsSection from "./LegacyNightsSection"
import Payments from "./Payments"
import ReservationDatesContextProvider from "./ReservationDatesContext"
import SharedBillingContextProvider, {
  SharedBillingContext,
} from "./SharedBillingContext"
import { hasDisputedInvoice } from "./helpers"

const Billing = ({
  ledgerId,
  paymentMethods,
  isMonthToMonth,
  pricingUrl,
  monthlyRateOptions,
  contactBoat,
  ratePreservationEnabled,
  hasPreservedRates,
  editable,
  m2mPercentBasedItemsEnabled = false,
}) => {
  const marinaSlug = getCurrentMarinaSlug()

  const {
    invoices: {
      data: invoiceData,
      isLoading: isLoadingInvoices,
      isError: isInvoicesError,
    },
    items: {
      collapsedData: items,
      isLoading: isLoadingItems,
      isError: isItemsError,
    },
    refreshItems,
    refreshPayments,
    reservationId,
    contractQuoteId,
  } = useContext(SharedBillingContext)

  const scheduledInvoices =
    invoiceData?.filter(
      (invoice) =>
        invoice.display_status === "Scheduled" &&
        !isBefore(parseISO(invoice.due_date), startOfToday())
    ) || []
  const hasScheduledInvoice = scheduledInvoices.length > 0

  return (
    <>
      <h3 className="text-lg font-semibold">Billing</h3>
      <Items
        items={items}
        ledgerId={ledgerId}
        contractQuoteId={contractQuoteId}
        reservationId={reservationId}
        paymentMethods={paymentMethods}
        refreshItems={refreshItems}
        refreshPayments={refreshPayments}
        isLoading={isLoadingItems}
        isError={isItemsError}
        isMonthToMonth={isMonthToMonth}
        hasScheduledInvoice={hasScheduledInvoice}
        pricingUrl={pricingUrl}
        monthlyRateOptions={monthlyRateOptions}
        contactBoat={contactBoat}
        ratePreservationEnabled={ratePreservationEnabled}
        hasPreservedRates={hasPreservedRates}
        editable={editable}
        m2mPercentBasedItemsEnabled={m2mPercentBasedItemsEnabled}
      />
      <Payments
        payments={invoiceData}
        refreshItems={refreshItems}
        refreshPayments={refreshPayments}
        reservationId={reservationId}
        marinaSlug={marinaSlug}
        paymentMethods={paymentMethods}
        isLoading={isLoadingInvoices}
        isError={isInvoicesError}
        editable={editable}
      />
      <CancelContractModal
        reservationId={reservationId}
        contractQuoteId={contractQuoteId}
        hasDisputes={hasDisputedInvoice(invoiceData)}
        deprecatedButtonTargetId="cancel-contract-modal-mbm-button"
      />
      <DangerousDeprecatedOldReactAdapterComponent
        targetId="itinerary-section-mbm"
        weAcknowledgeThisTechniqueIsDangerous
      >
        <LegacyItinerarySection contractQuoteId={contractQuoteId} />
      </DangerousDeprecatedOldReactAdapterComponent>
      <DangerousDeprecatedOldReactAdapterComponent
        targetId="nights-section-mbm"
        weAcknowledgeThisTechniqueIsDangerous
      >
        <LegacyNightsSection />
      </DangerousDeprecatedOldReactAdapterComponent>
    </>
  )
}

const BillingContainer = ({
  featureFlags,
  reservationCheckInDate,
  reservationCheckOutDate,
  reservationId,
  contractQuoteId,
  ...props
}) => {
  return (
    <ReservationDatesContextProvider
      reservationCheckInDate={reservationCheckInDate}
      reservationCheckOutDate={reservationCheckOutDate}
    >
      <SharedBillingContextProvider
        reservationId={reservationId}
        contractQuoteId={contractQuoteId}
        editable={props.editable}
      >
        <FeatureFlagContextProvider featureFlags={featureFlags}>
          <Billing {...props} />
        </FeatureFlagContextProvider>
      </SharedBillingContextProvider>
    </ReservationDatesContextProvider>
  )
}

Billing.propTypes = {
  ledgerId: PropTypes.string.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  isMonthToMonth: PropTypes.bool.isRequired,
  pricingUrl: PropTypes.string.isRequired,
  monthlyRateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  contactBoat: PropTypes.shape({
    lengthOverall: PropTypes.number.isRequired,
    beam: PropTypes.number.isRequired,
    squareFeet: PropTypes.number.isRequired,
  }),
  ratePreservationEnabled: PropTypes.bool.isRequired,
  hasPreservedRates: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
}

BillingContainer.propTypes = {
  ...Billing.propTypes,
  featureFlags: PropTypes.shape({
    editDiscounts: PropTypes.bool,
    editDates: PropTypes.bool,
  }),
  reservationCheckInDate: PropTypes.string.isRequired,
  reservationCheckOutDate: PropTypes.string,
  contractQuoteId: PropTypes.number,
  reservationId: PropTypes.number.isRequired,
  m2mPercentBasedItemsEnabled: PropTypes.bool,
}

export default BillingContainer
